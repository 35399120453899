import React from 'react';
import { Layout } from '../components/layout.component';
import { useI18next } from 'gatsby-plugin-react-i18next';

export default function Home() {
    const { t, navigate } = useI18next();
    return <Layout>
        <div className="homepage">
        <div className="section">
            <h2>{t('404 Page not found')}</h2>
      </div>
        </div>
    </Layout>
}